import { IHeaderSearchIcon } from 'models/layouts/header.model';
import { ILink } from 'models/link.model';
import { IGroupMenuLinkItem } from 'shared/models/groupMenuLinkItem.model';
import { EUserGroup } from 'shared/enums/user/userGroup.enum';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { AVAILABLE_GROUPS_REPORT } from '../client/reports.const';
import { NavigatorHelper } from 'shared/utils/navigatorHelper.utils';
import { sanitizeUrl } from '@braintree/sanitize-url';

export const defaultHeaderLinks: Array<ILink> = [
  { to: '/pokupatelyam/', text: 'Покупателям' },
  { to: '/postavschikam/', text: 'Поставщикам' },
];

export const mainHeaderLinks: Array<ILink> = [
  { to: '/pokupatelyam/', text: 'Покупателям' },
  { to: '/postavschikam/', text: 'Поставщикам' },
  { to: '/about/', text: 'О компании' },
  { to: '/contact/', text: 'Контакты' },
];

export const defaultHeaderIcons: Array<IHeaderSearchIcon> = [
  { to: '/', src: 'action/compare' },
  { to: '/', src: 'action/favourites' },
];
export const SUPPLIER_HEADER_LINKS: Array<IGroupMenuLinkItem> = [
  {
    label: 'Главная',
    path: '/supplier/qualifications/intro/',
    groups: [EUserGroup.Supplier],
    guardFunction: () => UserHelper.isSupplier && !SupplierHelper?.isQualificationAccepted,
    activeOn: ['supplier/qualifications/'],
  },
  {
    label: 'Заказы',
    path: '/supplier/orders/?orderType=all',
    src: EIconPath.ActionOrderSvg,
    groups: [EUserGroup.Supplier],
  },
  {
    label: 'Каталоги',
    path: '/supplier/catalogs/',
    src: EIconPath.ActionCatalogSvg,
    groups: [EUserGroup.Supplier],
  },
  {
    label: 'Отчёты',
    path: '/supplier/reports/',
    src: EIconPath.ActionReportSvg,
    groups: [EUserGroup.Supplier],
  },
  {
    label: 'Настройки',
    path: '/supplier/settings/',
    src: EIconPath.ActionSettingsSvg,
    groups: [EUserGroup.Supplier],
  },
  {
    label: 'Потребности',
    path: '/supplier/needs',
    groups: [EUserGroup.Supplier],
  },
  {
    label: 'Написать в поддержку',
    src: EIconPath.ActionReviewSvg,
    groups: [EUserGroup.Supplier],
    actionFunction: () => {
      window.open('mailto:support@maksmart.ru');
    },
    isBorderTop: true,
  },
  {
    label: 'База знаний поставщика',
    src: EIconPath.NavigationHelpSvg,
    groups: [EUserGroup.Supplier],
    guardFunction: () => !!useRuntimeConfig()?.public?.supplierKnowledgeBaseUrl,
    actionFunction: () => {
      NavigatorHelper.openNewWindow(sanitizeUrl(useRuntimeConfig()?.public?.supplierKnowledgeBaseUrl));
    },
  },
];

export const CLIENT_HEADER_LINKS: Array<IGroupMenuLinkItem> = [
  {
    label: 'Профиль',
    path: '/client/profile/',
    groups: [
      EUserGroup.ClientMpErp,
      EUserGroup.ClientAuditor,
      EUserGroup.ClientAdmin,
      EUserGroup.ClientFinContr,
      EUserGroup.ClientBoss,
      EUserGroup.ClientErp,
      EUserGroup.ClientCategoryManager,
    ],
  },
  {
    label: 'Управление ОЗМ',
    path: '/client/ozm',
    groups: [
      EUserGroup.ClientCategoryManager,
    ],
  },
  {
    label: 'Компания',
    path: '/client/company/',
    src: EIconPath.ActionCompanySvg,
    groups: [EUserGroup.ClientAdmin],
  },
  {
    label: 'Сотрудники',
    path: '/client/employees/',
    src: EIconPath.ActionCompanySvg,
    groups: [EUserGroup.ClientAdmin],
    guardFunction: () => !UserHelper.isIntegration,
  },
  // {
  //   label: 'Правила заказа',
  //   path: '/client/order-rules/',
  //   src: EIconPath.ActionSettingsSvg,
  //   roles: [EUserGroup.ClientAdmin, EUserGroup.ClientFinContr],
  // },
  {
    label: 'Лимиты',
    path: '/client/limits/',
    src: EIconPath.ActionLinkSvg,
    groups: [
      EUserGroup.ClientMpErp,
      EUserGroup.ClientAuditor,
      EUserGroup.ClientAdmin,
      EUserGroup.ClientFinContr,
      EUserGroup.ClientBoss,
      EUserGroup.ClientErp,
    ],
  },
  {
    label: 'Заказы',
    path: '/client/orders/',
    src: EIconPath.ActionOrderSvg,
    groups: [
      EUserGroup.ClientMpErp,
      EUserGroup.ClientAdmin,
      EUserGroup.ClientBoss,
      EUserGroup.ClientErp,
      EUserGroup.ClientFinContr,
      EUserGroup.ClientAuditor,
    ],
  },
  {
    label: 'Отчёты',
    path: '/client/reports/',
    src: EIconPath.ActionReportSvg,
    groups: AVAILABLE_GROUPS_REPORT,
  },
  {
    label: 'Поставки',
    path: '/client/shipments/',
    src: EIconPath.ActionDeliverySvg,
    groups: [
      EUserGroup.ClientMpErp,
      EUserGroup.ClientAdmin,
      EUserGroup.ClientErp,
    ],
  },
  {
    label: 'Аналитика',
    path: '/client/analytics/',
    src: EIconPath.ActionDeliverySvg,
    groups: [
      EUserGroup.ClientAdmin,
      EUserGroup.ClientBoss,
    ],
  },
  {
    label: 'Помощь',
    path: '/client/help/',
    src: EIconPath.ActionDeliverySvg,
    groups: [
      EUserGroup.ClientMpErp,
      EUserGroup.ClientAdmin,
      EUserGroup.ClientBoss,
      EUserGroup.ClientErp,
      EUserGroup.ClientFinContr,
      EUserGroup.ClientAuditor,
    ],
  },
];
